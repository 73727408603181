import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
export default () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            home {
              title
              description
            }
          }
        }
      }
    `}
    render={data => (
      <>
      <div className="hero-header">
        <div className="headline">Are you looking for NextStepHQ?</div>
        <div className="primary-content">
          <p>The amazing tool which lets you increase your website conversions with Contextual CTAs and prompts. If yes, then you have come to the right place but we decided to sunset the product.</p>
          <p>We started out with the belief that we can improve the B2B buyer journey with timely and contextual calls to action, but after working with close to 14 customers what we found out was glaring enough to look inwards into the problem statement.

We realized the B2B buyer journey starts way before the customer even lands on your page, most of it is happening in a coffee shop when a friend mentions a product or when someone visits the website of your customer with your logo somewhere. We might be able to help influence a small fraction of that traffic but nothing significant for us to build a SaaS tool generating millions in revenue. In other words not enough for us to leave our cushy jobs and spend countless hours of engineering and product effort in pursuit of happiness.

We will maintain this landing page for a year or so to remind ourselves what we started and to let people know that we are no longer active.</p>
        </div>
        <p><i>- Ravi Vyas and Abhishek Nandi</i></p>
        <div className="container-h">
          <div className="grid-item">
            <a href="https://ravivyas.com" target="_blank" title="Click to open Ravi's Website"><img className="avatar" src="/assets/ravi-vyas.jpeg" title="Ravi Vyas" alt= "Ravi's Image"/></a>
          </div>
          <div className="grid-item">
            <a href="https://www.abhisheknandi.com" target="_blank" title="Click to open Abhishek's Website"><img className="avatar" src="/assets/abhishek-nandi.jpeg" title="Abhishek Nandi" alt="Abhishek's Image"/></a>
          </div>
        </div>
      </div>
      </>
    )}
  />
)